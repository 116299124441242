<template>
  <div style="background: #F7F7F7;">
    <pcHeaders></pcHeaders>
    <div style="display: flex;justify-content: center;">
      <div class="w">
        <div class="content">
          <div class="leftBox">
            <div class="photoBox">
              <div>
                <div class="center">
                  <img style="width: 64px;height: 64px;border-radius: 50%"
                       :src="messageFromChild.userphoto"
                       v-if="messageFromChild.userphoto !== null"
                       alt="">
                  <img style="width: 64px;height: 64px;border-radius: 50%"
                       src="../../assets/images/noUser.png"
                       v-else
                       alt="">
                </div>

                <div class="center"
                     style="margin-top: 15px;">
                  <div class="text">
                    {{ messageFromChild.realname }}
                  </div>

                </div>

                <div class="yue">
                  账户余额: {{ messageFromChild.balance }}
                </div>
              </div>
            </div>

            <div class="tabBox">
              <div :class="tabIndex == 1 ? 'optionBox active' : 'optionBox'"
                   @click="nav(1)">
                <img v-if="tabIndex == 1"
                     src="../../assets/images/zaixue1.png"
                     alt="">
                <img v-else
                     src="../../assets/images/zaixue.png"
                     alt="">
                在学进度
              </div>

              <div :class="tabIndex == 2 ? 'optionBox active' : 'optionBox'"
                   @click="nav(2)">
                <img v-if="tabIndex == 2"
                     src="../../assets/images/suoyou1.png"
                     alt="">
                <img v-else
                     src="../../assets/images/suoyou.png"
                     alt="">
                课程资源
              </div>
              <!-- <div v-if="examShow">
                <div :class="tabIndex == 7  && examShow ? 'optionBox active' : 'optionBox'"
                     @click="nav(7)">
                  <img v-if="tabIndex == 7"
                       src="../../assets/images/exam1.png"
                       alt="">
                  <img v-else
                       src="../../assets/images/exam.png"
                       alt="">
                  我的考试
                </div>
              </div> -->

              <div :class="tabIndex == 3? 'optionBox active' : 'optionBox'"
                   @click="nav(3)">
                <img v-if="tabIndex == 3"
                     src="../../assets/images/dingdan1.png"
                     alt="">
                <img v-else
                     src="../../assets/images/dingdan.png"
                     alt="">
                订单管理
              </div>

              <div :class="tabIndex == 4? 'optionBox active' : 'optionBox'"
                   @click="nav(4)">
                <img v-if="tabIndex == 4"
                     src="../../assets/images/zhanghao1.png"
                     alt="">
                <img v-else
                     src="../../assets/images/zhanghao.png"
                     alt="">
                账号管理
              </div>

              <div :class="tabIndex == 5? 'optionBox active' : 'optionBox'"
                   @click="nav(5)">
                <img v-if="tabIndex == 5"
                     src="../../assets/images/chankan1.png"
                     alt="">
                <img v-else
                     src="../../assets/images/chakan.png"
                     alt="">
                证书查看
              </div>

              <div :class="tabIndex == 6? 'optionBox active' : 'optionBox'"
                   @click="nav(6)">
                <img v-if="tabIndex ==6"
                     src="../../assets/images/zsslh.png"
                     alt="">
                <img v-else
                     src="../../assets/images/zssl.png"
                     alt="">
                证书申领
              </div>
            </div>
          </div>

          <div class="rightBox">
            <schedule v-if="tabIndex == 1"
                      @childEvent="handleChildEvent"
                      @childEvent2="handleChildEvent2"
                      @eventName="eventName"
                      @goUser="goUser"
                      @isMember="onIsMember"></schedule>
            <allClasses v-if="tabIndex == 2"
                        :parentData="isMember"></allClasses>
            <order v-if="tabIndex == 3"></order>
            <account v-if="tabIndex == 4"
                     @childEvent32="handleChildEvent"></account>
            <certView v-if="tabIndex == 5"></certView>
            <application v-if="tabIndex == 6"></application>
            <exam v-if="tabIndex == 7 "></exam>
          </div>
        </div>
      </div>
    </div>
    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import schedule from './component/studySchedule.vue';
import allClasses from './component/allClasses.vue';
import order from './component/orderManagement.vue';
import account from './component/account.vue';
import certView from './component/certificateView.vue';
import application from './component/application.vue';
import exam from './component/exam.vue';
import { mapMutations } from 'vuex';
import { checkUserQuiz, checkToken,getCodeByParent } from '@/api/study.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom,
    schedule,
    allClasses,
    order,
    account,
    certView,
    application,
    exam
  },
  data() {
    // 这里存放数据
    return {
      tabIndex: 1,
      messageFromChild: {}, //
      urlInfo: {},
      examShow: false,
      isMember: ''
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    ...mapMutations('m_user', ['updateToken']),
    onIsMember(data) {
      this.isMember = data;
    },
    eventName() {
      //提示未支付订单弹框
      this.nav(3);
    },
    goUser() {
      //提示去完善信息
      this.nav(4);
    },
    nav(id) {
      this.tabIndex = id;
      if (id == 2) {
        //window.location.href = this.urlInfo.studyUrl + '&token=' + localStorage.getItem('token');
        if (this.isMember == '02') {
          this.$confirm('您已经是会员了，可免费学习课程资源库所有视频，点击确定前往', '提示', {
            confirmButtonText: '确定',
            type: 'success'
          }).then(() => {
            this.$router.push('/resourcesList');
          });
        }
      } else if (id == 7) {
        window.location.href = this.urlInfo.quizUrl + '&token=' + localStorage.getItem('token');
      }
    },
    //判断是否有考试
    checkUserQuizs() {
      checkUserQuiz({}).then(res => {
        console.log(res.data);
        if (res.data.data.result !== '00') {
          this.examShow = true;
        } else {
          this.examShow = false;
        }
      });
    },
    handleChildEvent2(data) {
      this.urlInfo = data;
    },
    handleChildEvent(data) {
      this.messageFromChild = data; // 接收到子组件传递的数据后更新状态
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.query.token) {
      checkToken({ token: this.$route.query.token }).then(res => {
        console.log(res.data.data.checkToken);
        //检测token能不能用，能用再存储
        if (res.data.data.checkToken) {
          // 拿到密钥存在本地存储
          this.updateToken(this.$route.query.token);
          //拿到密钥存在本地存储;
          localStorage.setItem('token', this.$route.query.token);
        }
        this.checkUserQuizs();
      });
    } else if (this.$route.query.id == 8) {
      getCodeByParent({}).then(res => {
        this.messageFromChild.realname = res.data.data.realname;
        this.messageFromChild.userphoto = res.data.data.userphoto;
        this.messageFromChild.balance = res.data.data.balance;
         this.nav(3);
      });
    } else {
      this.checkUserQuizs();
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    console.log();
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.center {
  display: flex;
  justify-content: center;
}
.w {
  width: 1200px;
  background: #f7f7f7;
  padding: 20px 0;
  font-family: Regular;
  .content {
    display: flex;
    justify-content: space-between;
    .leftBox {
      width: 200px;
      .photoBox {
        width: 200px;
        height: 190px;
        background: #ffffff;
        border-radius: 6px;
        padding-top: 19px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        .text {
          font-size: 16px;
          color: #333333;
          font-weight: bold;
        }
        .yue {
          margin-top: 19px;
          font-weight: bold;
          font-size: 14px;
          color: #ff8000;
          width: 164px;
          height: 42px;
          background: #fff3e6;
          border-radius: 21px;
          text-align: center;
          line-height: 42px;
        }
      }

      .tabBox {
        margin-top: 12px;
        width: 200px;
        background: #ffffff;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 36px 18px 20px 22px;
        font-size: 16px;
        .optionBox {
          width: 164px;
          height: 50px;
          border-radius: 6px;
          line-height: 50px;
          margin-bottom: 20px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
        }
        .active {
          color: #ffffff !important;
          background: #2878ff;
        }
      }
    }

    .rightBox {
      width: 988px;
    }
  }
}
</style>